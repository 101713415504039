<template>

  <!-- blue-grey darken-4  #263238 -->
  <v-card v-if="isShow" id="id_root" height="50px" width="100%" color="blue-grey darken-4 rounded-0" > 

      <!-- <v-app-bar dark height="50px" width="100%" color="#263238"> -->
      <v-app-bar dark height="50px" width="100%" style="background: linear-gradient(to right, #F8F8F8, #686767);">

        <v-sheet width="500px" height="100%" class="py-1 transparent d-flex elevation-0" style="position: absolute; left: 0;">
          <v-img :aspect-ratio="1" max-width="60px" :src="logoImage" class="pa-4 ml-2 mt-0 transparent" style="filter: contrast(150%)"></v-img>
          <!-- <v-sheet class="ml-1 my-auto transparent fontWebMgmt">AED Web Management System</v-sheet>  -->
          <v-sheet class="ml-2 my-auto transparent fontWebMgmt">M.A.P. Resus</v-sheet>
        </v-sheet>        

        <v-spacer></v-spacer>

        <v-card class="mr-8 my-auto pa-0 rounded-0 border-0 elevation-0 transparent">

          <!-- <v-tabs right dense v-model="selectedTab" class="transparent" center-active>
            <v-tab dark to="/Dashboard" width="120" class="white--text transparent" router>
              <svg-icon type="mdi" color="orange" :path="pathViewDashboard"></svg-icon>
              <v-sheet height="25" class="pt-1 px-2 rounded-lg" :class="tabColorDash">Dashboard</v-sheet>
            </v-tab>
            <v-tab to="/DevInfo" width="120" class="white--text transparent" router>
              <svg-icon type="mdi" color="orange" :path="pathHeartFlash"></svg-icon>
              <v-sheet height="25" class="pt-1 px-2 rounded-lg" :class="tabColorDev">Device Info</v-sheet>            
            </v-tab> 
            <v-tab to="/EventManagement" width="120" class="white--text transparent" router>
              <svg-icon type="mdi" color="orange" :path="pathCalendarCheck"></svg-icon>
              <v-sheet height="25" class="pt-1 px-2 rounded-lg" :class="tabColorMgmt">Event Management</v-sheet>
            </v-tab> 
          </v-tabs>   -->

          <v-tabs right dense v-model="selectedTab" background-color="transparent"  class="" center-active>
            <v-tab to="/Dashboard" router width="120" class="white--text transparent text-capitalize">
              <v-sheet height="30" class="pt-1 px-2 rounded-lg fontBar d-flex" :class="tabColorDash">
                <svg-icon type="mdi" color="orange" :path="pathViewDashboard"></svg-icon> 
                <v-sheet class="mt-0 ml-1 transparent" :class="tabColorDash">Dashboard</v-sheet>
              </v-sheet>
            </v-tab>
            <v-tab to="/DevInfo" router width="120" class="white--text transparent text-capitalize">
              <v-sheet height="30" class="pt-1 px-2 rounded-lg fontBar d-flex" :class="tabColorDev">
                <svg-icon type="mdi" color="orange" :path="pathHeartFlash"></svg-icon>
                <v-sheet class="mt-0 ml-1 transparent" :class="tabColorDev">Device Info</v-sheet>
              </v-sheet> 
            </v-tab> 
            <v-tab to="/EventManagement" router width="120" class="white--text transparent text-capitalize">
              <v-sheet height="30" class="pt-1 px-2 rounded-lg fontBar d-flex" :class="tabColorMgmt">
                <svg-icon type="mdi" color="orange" :path="pathCalendarCheck"></svg-icon>
                <v-sheet class="mt-0 ml-1 transparent" :class="tabColorMgmt">Event Management</v-sheet>
              </v-sheet>
            </v-tab> 
          </v-tabs>

        </v-card> 
        
        <v-sheet class="ma-0 pa-0 transparent" style="position: absolute; right: 0;">
        <v-menu  bottom left color="transparent">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon text tile fab v-bind="attrs" v-on="on" class="ma-0 pa-0 transparent rounded-0 border-0 elevation-0 fontB">
              <svg-icon type="mdi" :path="pathVertDots"></svg-icon>
            </v-btn>
            <!-- <v-btn icon v-bind="attrs" v-on="on" style="background-color: inherit;"><svg-icon type="mdi" :path="pathVertDots"></svg-icon></v-btn> -->
          </template>

          <v-list dense width="150px" class="pa-0 blue-grey darken-3" active-class="white">
            <v-list-item class="ma-0 pa-0" width="100%" height="100%">
              <!-- <v-list-item-title class="white--text" @click="onAccount">Account</v-list-item-title> -->
              <v-list-item-content class="ma-0 pa-0">
                <v-btn text class="ma-0 pa-0 white--text text-capitalize fontN" @click="onAccount">Account</v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-list-item  class="ma-0 pa-0" width="100%" height="100%">
              <v-list-item-content class="ma-0 pa-0">
                <v-btn text class="ma-0 pa-0 white--text text-capitalize fontN" @click="onSetting">Setting</v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-list-item  class="ma-0 pa-0" width="100%" height="100%">
              <v-list-item-content class="ma-0 pa-0">
                <v-btn text class="ma-0 pa-0 white--text text-capitalize fontN" @click="onChangePassword" 
                style="white-space: normal; flex: auto;">Change Password</v-btn>
              </v-list-item-content>
            </v-list-item>            
            <v-list-item class="ma-0 pa-0" width="100%" height="100%">
              <v-list-item-content class="ma-0 pa-0">
                <v-btn text class="ma-0 pa-0 white--text text-capitalize fontN" @click="onAbout">About</v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="ma-0 pa-0" width="100%" height="100%">
              <v-list-item-content class="ma-0 pa-0">
                <v-btn text class="ma-0 pa-0 white--text text-capitalize fontN" @click="onLogout">Logout</v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        </v-sheet>

      </v-app-bar>

      <DialogKeyValue title="User Information" :messages="userInfo"  :pAct="iAct1" @DialogKeyValueClose="onEmitDialogClose1"/>

      <DialogUserSetting pTitle="User Settings" :pAct="bActUsr" @DialogUserSettingClose="onEmitDialogUserSettingClose"/>

      <DialogPassword pTitle="Change Password" :pAct="bActPw" @DialogClose="onEmitPwClose"/>

      <DialogAbout :messages="aboutInfo" title="About" :pAct="iAct2" @DialogKeyValueClose="onEmitDialogClose2"/>

      <!-- <v-snackbar right v-model="sBar.open" :color="sBar.color" :timeout="sBar.time">{{ sBar.message }}</v-snackbar>  -->

    </v-card>   
   
</template>


<script>
import axios from 'axios'
import * as Ut from '@/js/ut.js'
import * as Tbl from '@/js/tables.js'
import cookies from '@/js/Cookie.js'
import sess from '@/js/SessionStorage.js'
import DialogKeyValue from '@/components/Common/DialogKeyValue'
import DialogUserSetting from '@/components/Common/dialogUserSetting'
import DialogPassword from '@/components/Common/DialogPassword'
import DialogAbout from '@/components/Common/dialogAbout'

import SvgIcon from '@jamescoyle/vue-icon'
import { mdiDotsVertical,mdiViewDashboard, mdiHeartFlash, mdiCalendarCheck } from '@mdi/js';

export default {
  name: 'TopBar',

  components: {
    SvgIcon, DialogKeyValue, DialogUserSetting, DialogPassword, DialogAbout
  },

  props: {

  },

  data () {
    return {
      pathVertDots: mdiDotsVertical,
      iAct1: 0,
      iAct2: 0,
      bActUsr: false,
      bActPw: 0,
      selectedTab: "",
      isShow: false,
      //logoImage: require("@/assets/Images/Background/Company Logo.png"),
      //logoImage: require("@/assets/Images/icons/aed1.png"),
      logoImage: require("@/assets/Images/icons/Firefly2.png"),
      sBar: {"open": false, "message": "", "color": "error", "time": 3000},
      vCookie: {},
      userInfo: {},
      aboutInfo: {},

      tabColorDash: 'white--text transparent',
      tabColorDev: 'white--text transparent',
      tabColorMgmt: 'white--text transparent',
      pathViewDashboard: mdiViewDashboard, 
      pathHeartFlash: mdiHeartFlash, 
      pathCalendarCheck: mdiCalendarCheck      
    }
  },

  methods: {

    //-------------------------------------------
    //         onLogout
    // DESCRIPTION:  Logout handler
    //-------------------------------------------
    onLogout () {
      cookies.delete("aa"); //remove Auth flag
      sess.delete("dd");  //remove sessionStorage
      this.axiosLogout(); //other cookies deleted when axios reply true
      // erase current user info, and login info
      Ut.WriteUser(Ut.createUser()); // assign empty user
      Ut.WriteOtp2Fa(Ut.createOtp2fa()); // assign empty otp2fa
      Ut.clearArray(this.aeditems);
      Ut.clearArray(this.testrpts);
      Ut.clearArray(this.emergrpts);
     
      // change page
      if (this.$route.path !== '/') {
        this.$router.push('/');
      } 
    },

    //-------------------------------------------
    //         onLogout
    // DESCRIPTION:  Login handler
    //-------------------------------------------
    onLogin (xdata) {
      //console.log('#onLogin', xdata);
      this.vCookie = setTimeout(this.onCookieExpire, this.$config.COOKIE_EXPIRE * 1000);
    },

    //-------------------------------------------
    //         onSetting
    // DESCRIPTION:  Setting Email addr for report delivery
    //-------------------------------------------
    onSetting () {
      this.bActUsr = true;
    },

    //-------------------------------------------
    //         onAbout
    // DESCRIPTION:  System Info
    //-------------------------------------------
    onAbout () {
      this.aboutInfo = {
        //"Software Version": process.env.VUE_APP_REVISION,
        //"Release Date": process.env.VUE_APP_RELEASE_DATE,
        "Software Version": this.software.version,
        "Release Date": this.software.date,
        "": ""
      }
      if (this.iAct2++ >= 60000) this.iAct2 = 0;
    },

    //-------------------------------------------
    //         onChangePassword
    // DESCRIPTION:  handler of current user change password
    //-------------------------------------------    
    onChangePassword () {
      this.bActPw++;
    },

    //-------------------------------------------
    //         onAccount
    // DESCRIPTION:  showing current login account info
    //-------------------------------------------   
    onAccount () {
      // display Ut.currentUser:  2 columns:  key :  value
      if (!cookies.exist("us")) return;
      let str = cookies.get("us");
      let vObj = JSON.parse(str);
      /* this.userInfo = {
        "ID": Ut.currentUser.id,
        "Username":   vObj.username,
        "Name":       vObj.name,
        "Phone 1":    vObj.phone1,
        "Phone 2":    vObj.phone2,
        "Mobile":     vObj.mobileNo,
        "Address":    vObj.address,
        "email":      vObj.email,
        "Email 1":    vObj.email1,
        "Email 2":    vObj.email2,
        "User Type":  vObj.userType,
        "Active ?":   vObj.isActive ? "Yes" : "No",
        "Deleted ?":  vObj.isDeleted ? "Yes" : "No",
        "Created at": vObj.createdAt,
        "Added by":   vObj.addedBy,
        "Creaetd at": vObj.createdAt,
        "Updated by": vObj.updatedBy
      } */
      // if vObj.email == "notProvide", email = "/";  == "", email empty

      this.userInfo = {
        //"ID":         vObj.ID,
        "Username":   vObj.Username,
        //"Name":       vObj.Name,
        //"Email":      vObj.email,
        "Email":      vObj.email.includes('notProvide')?'/':vObj.email,
        //"Email 1":    vObj.email1,
        //"Email 2":    vObj.email2,
        //"Phone 1":    vObj.phone1,
        //"Phone 2":    vObj.phone2,        
        //"User Type":  vObj.userType
        "": ""
      }
      //this.userInfo = Tbl.createUserInfo(vObj);
      if (this.iAct1++ >= 60000) this.iAct1 = 0;
    },

    onEmitDialogClose1 () {
      
    },

    onEmitDialogClose2 () {
      
    },

    onEmitDialogUserSettingClose () {
      this.bActUsr = false;
    },

    onEmitPwClose () {
      this.bActPw = 0;
    },

    onCookieExpire () {
      this.sBar.message = "Cookie expired, please login again."
      this.sBar.open = true;
      this.vCookie = null;

      this.axiosLogout(); //other cookies deleted when axios reply true
      // erase current user info, and login info
      Ut.WriteUser(Ut.createUser()); // assign empty user
      Ut.WriteOtp2Fa(Ut.createOtp2fa()); // assign empty otp2fa
      Ut.clearArray(this.aeditems);
      Ut.clearArray(this.testrpts);
      Ut.clearArray(this.emergrpts);     
      // change page
      if (this.$route.path !== '/') {
        this.$router.push('/');
      }
    },

    // DESCRIPTION:  receive hide/unhide top bar req from other modules
    rxEmit (xflag) {
      this.isShow = xflag;
    },

    // DESCRIPTION:  receive logout req from other modules
    rxEmitLogout (xcode) {
      //console.log("##onLogout-code ",xcode); 
      this.onLogout();
    },

    // DESCRIPTION:  receive change tab req from other modules:  EventManagement tab
    rxEmitTab(xTab) {
      switch(xTab) {
        case "/Dashboard": this.selectedTab = xTab; break;
        case "/DevInfo": this.selectedTab = xTab; break;
        case "/EventManagment": this.selectedTab = xTab; break;
        default: break;
      }
    },

    setTabColor(color1, color2, color3) {
      this.tabColorDash = color1;
      this.tabColorDev = color2;
      this.tabColorMgmt = color3;
    },

    clearCookies() {
      // if (this.$cookies.isKey("kk")) this.$cookies.remove("kk");
      if (cookies.exist("kk")) cookies.delete("kk");
      if (cookies.exist("us")) cookies.delete("us");
    },

    clearArray (xary) {
      if (xary.length > 0) {
        xary.splice(0); 
        xary.length = 0;
      }
    },

    // ---------------------------------------------
    // DESCRIPTION:  handle menu item - Logout
    async axiosLogout () {
      let vObj = {
        'method': 'POST',
        'url':  this.$config.apiBaseUrl + '/auth/logout',
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          //'Authorization': 'Bearer ' + this.$cookies.get()
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }
      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          // alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: null }
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            this.clearCookies();
          } else {
            console.log('#axiosLogout(1) error: ', resp.data.message);  // message from server
          }
        } else {
          console.log('#axiosLogout(2) error: receive null response');
          // this.openSnackBar('Device Info update fail', 'error');
        }
      } catch (error) {
        console.error('#axiosLogout(3) error: ' + error.response);
        // this.openSnackBar('Device Info update fail '+ error, 'error');
        //this.$cookies.remove("kk");
        //this.$cookies.remove("us");
        if (cookies.exist("kk")) cookies.delete("kk");
        if (cookies.exist("us")) cookies.delete("us");
        if (cookies.exist("aa")) cookies.delete("aa");
      }
    }
  },

  watch: {
    selectedTab: function (newVar) {
      //console.log("##TopBar", this.selectedTab, this.$router.history.current.fullPath);  // debug
      if (this.selectedTab === "/Dashboard") {
        //this.setTabColor('orange--text', 'white--text', 'white--text');
        this.setTabColor('white black--text', 'transparent', 'transparent');
      } else if (this.selectedTab === "/DevInfo") {
        this.setTabColor('transparent', 'white black--text', 'transparent');
      } else if (this.selectedTab === "/EventManagement") {
        this.setTabColor('transparent', 'transparent', 'white black--text');
      }
    }
  },

  mounted () {
    //console.log('##TopBar.vue');   //debug
    this.$root.$on('App_TopBar', (rxData) => { this.rxEmit(rxData) })
    this.$root.$on('App_Logout', (code) => { this.rxEmitLogout(code) })
    this.$root.$on('App_Tab', (xTab) => { this.rxEmitTab(xTab) })
  }
};
</script>

<style scoped>

.v-list-item:hover {
  background-color: #1565C0;
}

/*
.v-tab {
  text-transform: none !important;
  background-color: #263238;
} */

/*
.v-app-bar {
  background-color: #263238;
}  */

#id_root {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */      
}

#id_root::-webkit-scrollbar {
    display: none;
}

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
}

.fontBar {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;
  font-size: 1.1em;
}

.fontWebMgmt {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;
  color: #050505;
  font-size: 1.4em;
}

</style>